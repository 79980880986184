import React,{ useContext, useEffect, useState} from 'react';
import { Link } from 'react-router-dom'
import { makeStyles } from '@material-ui/core/styles';
import { HiArrowRight } from "react-icons/hi";

import './Blog.css';
import { ThemeContext } from '../../contexts/ThemeContext';
// import { blogData } from '../../data/blogData'
import SingleBlog from './SingleBlog/SingleBlog';
import axios from 'axios';


function Blog() {

    const { theme } = useContext(ThemeContext);

    const useStyles = makeStyles(() => ({
        viewAllBtn : {
            color: theme.tertiary, 
            backgroundColor: theme.primary,
            "&:hover": {
                color: theme.secondary, 
                backgroundColor: theme.primary,
            }
        },
        viewArr : {
            color: theme.tertiary, 
            backgroundColor: theme.secondary70,
            width: '40px',
            height: '40px',
            padding: '0.5rem',
            fontSize: '1.05rem',
            borderRadius: '50%',
            cursor: 'pointer',
            "&:hover": {
                color: theme.tertiary, 
                backgroundColor: theme.secondary,
            }
        },
    }));

    const classes = useStyles();
    const [posts, setPosts] = useState([]);
    const mediumURL = "https://api.rss2json.com/v1/api.json?rss_url=https://medium.com/feed/@sdekumarvikash";
    useEffect(() => {
      async function fetchPosts() {
        try{
            const {data} = await axios.get(mediumURL);
            if(data.status=="ok"){
                setPosts(data.items)
            }
        }
        catch(err){
            console.log(err);
        }
      }
      fetchPosts();
    }, []);
    return (
        <>
            {posts?.length > 0 && (
                <div className="blog" id="blog" style={{backgroundColor: theme.secondary}}>
                    <div className="blog--header">
                        <h1 style={{color: theme.primary}}>Blog</h1>
                    </div>
                    <div className="blog--body">
                        <div className="blog--bodyContainer">
                            {posts.slice(0, 3).reverse().map((blog, index) => (
                                <SingleBlog 
                                    theme={theme}
                                    title={blog.title}
                                    desc={blog.description}
                                    date={blog.pubDate}
                                    image={blog.image}
                                    
                                    url={blog.link}
                                    key={index}
                                    id={blog.id}
                                />
                            ))}
                        </div> 

                        {posts.length > 3 && (
                            <div className="blog--viewAll">
                                <Link to="/blog">
                                    <button className={classes.viewAllBtn}>
                                        View All
                                        <HiArrowRight className={classes.viewArr} />
                                    </button>
                                </Link>
                            </div>
                        )}
                    </div>
                </div>
            )}

        </>
    )
}

export default Blog
