export const experienceData = [
    {
        id: 1,
        company: 'Nascent Info Technological Pvt. Ltd.',
        jobtitle: 'Associate Software Engineer',
        startYear: 'May 2022',
        endYear: 'Present'
    },
    {
        id: 2,
        company: 'Nascent Info Technological Pvt. Ltd. ',
        jobtitle: 'Intern Software Engineer',
        startYear: 'Dec 2021',
        endYear: 'April 2022'
    },
    
]