export const educationData = [
    
    {
        id: 1,
        institution: 'Gujarat University, Ahmedabad',
        course: 'Bachelor of Computer Applications (BCA)',
        startYear: '2019',
        endYear: '2022'
    },
    {
        id: 2,
        institution: 'Gujarat Technological University, Ahmedabad',
        course: 'Master of Computer Applications (MCA)',
        startYear: '2022',
        endYear: 'Present'
    },
]